<template>
  <div class="video-card-container">
    <modal v-if="showModal" @on-close="closeModal" :component="componentToResolve" :component-props="selectedCard"></modal>
    <div class="video-card" v-for="(card, index) in videoList" :key="index">
      <div class="video-player">
        <a @click="open(card)" class="video-placeholder-container">
          <div class="video-background">
            <img :src="card.video_placeholder_url" width="150" alt="Imagen de fondo">
          </div>
          <div class="video-placeholder-icon">
            <img :src="cloudinary.VIDEO_ICON_PLACEHOLDER" alt="Icono de marcador de posición">
          </div>
        </a>
      </div>
      <p class="video-card-title">
        {{ card.video_description }}
      </p>
      <div class="video-card-brand">
        <img :src="card.brand_url" :height="card.brand_icon_size" alt="Icono de marca">
      </div>
    </div>
  </div>
</template>

<script>
import cloudinary from '@/constants/cloudinary';
import Modal from '@/components/structure/Modal.vue';
import VideoPreview from '@/components/basic/VideoPreview.vue';

export default {
  name: "VideoPlaceholderCard",
  components: {
    Modal,
  },
  data() {
    return {
      cloudinary: {},
      showModal: false,
    };
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    videoList: {
      type: Array,
      default () {
        return [];
      },
    },
  },
  computed: {
    componentToResolve() {
      return VideoPreview;
    },
  },
  created() {
    this.cloudinary = cloudinary;
  },
  methods: {
    open(card) {
      this.selectedCard = card;
      this.showModal = true;
    },
    closeModal(){
      this.showModal = false;
    },
  },
};
</script>

<style lang="scss" scoped="">
.video-card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.video-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 25rem;
  padding: 4rem 1rem;
  background-color: #ebf9ff;
  border-radius: 1.5rem;
  margin-bottom: 2rem;
}
.video-placeholder-container {
  @include flex-center;
  flex-direction: column;
  position: relative;
  transition: all .4s ease;
  &:hover {
    cursor: pointer;
    &::after {
      opacity: 0.75;
      background: white;
      transform:
        translate3d(0, 0, 0)
        scale3d(1, 1, 1)
        rotateX(0deg)
        rotateY(0deg)
        rotateZ(0deg)
        skew(0deg, 0deg);
    }
  }
  &::after {
    content: "";
    z-index: 0;
    position: absolute;
    min-height: 9.375rem;
    min-width: 9.375rem;
    opacity: 1;
    height: 100%;
    width: 100%;
    border-radius: 100%;
    transform:
      translate3d(0, 0, 0)
      scale3d(0, 0, 1)
      rotateX(0deg)
      rotateY(0deg)
      rotateZ(0deg)
      skew(0deg, 0deg);
    transform-style: preserve-3d;
    transition: all .4s ease;
  }
}
.video-placeholder-icon {
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.video-card-title {
  font-size: 1.4rem;
  line-height: 2.4rem;
  font-weight: 700;
  text-align: center;
  margin: 1rem 0;
}
.video-card-brand {
  margin-top: auto;
}
.video-background img {
  display: block;
  border-radius: 100%;
}
@include bp-small {
  .component-wrapper {
    width: 100%;
  }
}
@include bp-mediumlarge {
  .video-card-container {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .video-card {
    width: 25rem;
    min-height: 28rem;
  }
}

@include bp-large {
  .video-card {
    max-width: 17rem;
    min-height: 36rem;
  }
  .video-card-title {
    font-size: 1.6rem;
  }
}
</style>
