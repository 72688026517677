<template>
  <div class="external-news-container">
    <a v-for="(card, index) in newsList" :key="index" :href="card.url" class="news-card" target="_blank">
      <div :style="setStyleBackgroundImage(card.image_placeholder)" class="news-image-placeholder"></div>
      <div class="news-details">
        <div class="news-title">{{ card.title }}</div>
        <div class="news-description">{{ card.description }}</div>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: "ExternalNews",
  props: {
    newsList: {
      type: Array,
      default () {
        return [];
      },
    },
  },
  methods: {
    setStyleBackgroundImage(url) {
      return { 'background-image': `url(${url})` };
    },
  },
};
</script>

<style lang="scss" scoped="">
.external-news-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  margin: 3rem 0;
}
.news-card {
  @include flex-center;
  flex-direction: column;
  margin-bottom: 3rem;
  width: 100%;
  max-width: 23.125rem;
}
.news-image-placeholder {
  width: 15rem;
  height: 15rem;
  margin-bottom: 1rem;
  align-self: center;
  border-radius: 1.25rem;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}
.news-details {
  color: #0e1e57;
}
.news-title {
  line-height: 1.5rem;
  font-size: 1.125rem;
  font-weight: 400;
}
.news-description {
  line-height: 1.5rem;
  font-size: 1.25rem;
  font-weight: 500;
}
@include bp-mediumlarge {
  .external-news-container {
    flex-direction: row;
    align-items: baseline;
  }
}
</style>
